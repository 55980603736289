/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { object, func, bool, string as str } from 'prop-types';
import {
  Form,
  FormButton,
  FormDropdown,
  FormEmail,
  FormNumber,
  FormTextField,
} from '@thd-olt-component-react/form';
import { Typography } from '@one-thd/sui-atomic-components';
import moment from 'moment/moment';
import { readUploadFile } from './excelToJson';
import { HEAR_ABOUT_US_OPTIONS, MAX_PARTICIPANTS, OTHER_OPT } from '../utils/constants';
import { compareSpecialDates } from '../utils/helpers';

const WrappedFields = (props) => {
  const [participantsCount, setParticipantsCount] = useState(1);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);

  const [isMaxParticipants, setIsMaxParticipants] = useState(false);
  const [hasNegativeParticipants, setHasNegativeParticipants] = useState(false);

  const [showOtherField, setShowOtherField] = useState(false);

  const {
    onSubmit, workshopsData, disableButton, setDisableButton, storeId
  } = props;

  const formatDate = (dateStr) => {
    const date = new Date(Date.parse(dateStr));
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  };

  const checkWorkshopCap = (workshop, participantsToRegisterCount) => {
    const startDate = moment(workshop.startDate);
    const formattedStartDate = startDate.utc().format('YYYY-MM-DD[T]HH:mm:ss') + 'Z';
    const month = moment(formattedStartDate).format('MMMM');

    const specialDate = compareSpecialDates(startDate);
    const cap = readUploadFile(storeId, specialDate || month);

    setDisableButton(workshop.registeredParticipants + participantsToRegisterCount > cap);
  };

  const handleChangeWorkshop = (event) => {
    let workshop = event.target.value;
    if (selectedWorkshop !== null && selectedWorkshop.name !== workshop.name) {
      setDisableButton(false);
    }
    setSelectedWorkshop(workshop);
    checkWorkshopCap(workshop, participantsCount);
  };

  const handleOnDropdownChange = (event) => {
    setShowOtherField(event.target.value === OTHER_OPT);
  };

  const handleOnParticipantsChange = (event) => {
    const currentParticipantCount = parseInt(event.target.value, 10);
    const maxPart = currentParticipantCount > MAX_PARTICIPANTS;
    const negativePart = currentParticipantCount <= 0;
    if (maxPart) {
      setIsMaxParticipants(maxPart);
      return;
    }
    if (negativePart) {
      setHasNegativeParticipants(negativePart);
      return;
    }

    setParticipantsCount(currentParticipantCount);

    if (selectedWorkshop !== null) {
      checkWorkshopCap(selectedWorkshop, currentParticipantCount);
    }
  };

  useEffect(() => {
    const inpt = document.querySelector('.capped_input input[type="number"]');
    if (inpt) {
      inpt.min = 1;
      inpt.max = MAX_PARTICIPANTS;
    }
  }, []);

  return (
    <Form onSubmit={onSubmit} className="sui-mt-6 sui-grid sui-grid-cols-1 sui-gap-4 capped_input">
      <FormTextField
        name="name"
        label="Parent / Guardian Name"
        placeholder="Name"
        required
        validate={{
          regex: /^[a-zA-Z\s-]*[a-zA-Z]{2,}[a-zA-Z\s-]*$/,
          regexMessage: 'A valid name with first and last name is required',
          typeError: 'error',
        }}
      />
      <FormEmail name="email" label="Email" placeholder="johndoe@john.doe" required />
      <FormNumber
        name="participants"
        label="Number of Kids Attending"
        placeholder="1"
        defaultValue="1"
        required
        min={1}
        max={MAX_PARTICIPANTS}
        onChange={handleOnParticipantsChange}
      />
      {/* select workshop dropdown */}
      <FormDropdown
        name="workshop"
        label="Choose Your Workshop"
        options={workshopsData?.workshops?.map((workshop) => {
          return { value: workshop, label: `${workshop?.name} ${formatDate(workshop?.startDate)}` };
        })}
        onChange={handleChangeWorkshop}
      />
      {/* how did you hear about us? dropdown */}
      <FormDropdown
        name="howDidYouHear"
        label="How did you hear about us?"
        options={HEAR_ABOUT_US_OPTIONS.map((option) => {
          return { value: option, label: option };
        })}
        onChange={handleOnDropdownChange}
        required
      />
      {/* other textfield */}
      {
        showOtherField && (
          <FormTextField
            name="other"
            label="Other"
            placeholder="Specify..."
            required
          />
        )
      }
      {
        disableButton ? (
          <Typography variant="body-xs">
            Registration for your selected store is full. Please select another store or check back for next month's workshop.
          </Typography>
        )
          : (
            <Typography variant="body-xs">
              Due to the popularity of the Kids Workshops, we can't guarantee a kit for every
              participant. We recommend arriving early.
            </Typography>
          )
      }
      {isMaxParticipants && (
        <Typography variant="body-xs" weight="bold">
          * Limit {MAX_PARTICIPANTS} kids per household.
        </Typography>
      )}
      {hasNegativeParticipants && (
        <Typography variant="body-xs" weight="bold">
          * Please enter a valid number of participants (more than 0).
        </Typography>
      )}
      <FormButton
        variant="primary"
        label="Register"
        disabled={disableButton || isMaxParticipants || hasNegativeParticipants}
        className="sui-grid sui-grid-cols-1 sui-justify-items-end sui-gap-4"
      />
    </Form>
  );
};

WrappedFields.propTypes = {
  onSubmit: func.isRequired,
  disableButton: bool.isRequired,
  setDisableButton: func.isRequired,
  workshopsData: object.isRequired,
  storeId: str.isRequired
};

export default WrappedFields;
